import { useState, useEffect } from 'react'
import useTitle from '../hooks/useTitle'
import { useGetProductsQuery } from '../features/backend/backendApiSlice'
import { PRODUCT_ID_GIFT_CERTIFICATE } from '../utils/enums'
import Step0 from '../components/GiftCertificate/Step0'
import Step1 from '../components/GiftCertificate/Step1'
import Step2 from '../components/GiftCertificate/Step2'
import Step3 from '../components/GiftCertificate/Step3'

const GiftCertificateView = () => {
  useTitle('Подарочный сертификат')

  const { data, isError, isLoading, isSuccess } = useGetProductsQuery()
  const product = data ? data.find((elem) => elem.id == PRODUCT_ID_GIFT_CERTIFICATE) : null

  const designOptions = product ? product.variations[0].images.map(image => image.path) : []
  const colorOptions = [ '#000000', '#cccccc', '#ff0000', '#00ff00', '#0000ff' ]
  const valueOptions = Array.from({ length: 10 }, (v, i) => 1000 + i * 1000)

  const [step, setStep] = useState(0)
  const [design, setDesign] = useState(designOptions[0])
  const [color, setColor] = useState(colorOptions[0])
  const [value, setValue] = useState(valueOptions[0])

  useEffect(() => {
    setDesign(designOptions[0])
  }, [data])

  return (
    <div className="container">
      {step === 0 && <Step0 step={step} setStep={setStep} />}
      {step === 1 && <Step1 step={step} setStep={setStep} designOptions={designOptions} colorOptions={colorOptions} design={design} setDesign={setDesign} color={color} setColor={setColor} />}
      {step === 2 && <Step2 step={step} setStep={setStep} design={design} valueOptions={valueOptions} value={value} setValue={setValue} />}
      {step === 3 && <Step3 step={step} setStep={setStep} design={design} value={value} product={product} />}
    </div>
  )
}

export default GiftCertificateView