import { useState, useEffect } from 'react'

const ScrollableXContainer = ({ handle, className, displayArrows, children, ...props }) => {
  const [displayLeftArrow, setDisplayLeftArrow] = useState(false)
  const [displayRightArrow, setDisplayRightArrow] = useState(false)

  // check the container's children width on mount, and on children change, and set the arrows display accordingly
  useEffect(() => {
    const target = document.querySelector(handle)

    if (!target) return

    const scrollWidth = target.scrollWidth

    if (target.clientWidth < scrollWidth) {
      setDisplayRightArrow(true)
    } else {
      setDisplayRightArrow(false)
    }
  }, [children])

  const handleMouseDown = (e) => {
    e.preventDefault()
    // const target = e.target
    const target = document.querySelector(handle)

    if (!target) return

    const scrollWidth = target.scrollWidth
    const startX = e.pageX - target.offsetLeft
    const scrollLeft = target.scrollLeft

    target.style.cursor = 'grabbing'
    target.style.scrollBehavior = 'unset'

    const mouseMove = (e) => {
      const x = e.pageX - target.offsetLeft
      const walk = (x - startX) * 2
      target.scrollLeft = scrollLeft - walk

      if (target.scrollLeft > 0) {
        setDisplayLeftArrow(true)
      } else {
        setDisplayLeftArrow(false)
      }

      if (target.scrollLeft + target.clientWidth < scrollWidth) {
        setDisplayRightArrow(true)
      } else {
        setDisplayRightArrow(false)
      }
    }

    const mouseUp = () => {
      target.style.cursor = 'grab'
      target.style.scrollBehavior = 'smooth'
      document.removeEventListener('mousemove', mouseMove)
      document.removeEventListener('mouseup', mouseUp)
    }

    document.addEventListener('mousemove', mouseMove)
    document.addEventListener('mouseup', mouseUp)
  }

  const nudgeLeft = () => {
    const target = document.querySelector(handle)

    if (!target) return

    target.scrollLeft -= 100

    if (target.scrollLeft - 100 > 0) {
      setDisplayLeftArrow(true)
    } else {
      setDisplayLeftArrow(false)
    }

    setDisplayRightArrow(true)
  }

  const nudgeRight = () => {
    const target = document.querySelector(handle)

    if (!target) return

    target.scrollLeft += 100

    setDisplayLeftArrow(true)

    if (target.scrollLeft + 100 + target.clientWidth < target.scrollWidth) {
      setDisplayRightArrow(true)
    } else {
      setDisplayRightArrow(false)
    }
  }
  
  return (
    <div className="scrollable-x-wrapper">
      <div
        className={`scrollable-x-container ${className}`}
        {...props}
        onMouseDown={handleMouseDown}
      >
        {children}
      </div>
      {displayArrows && <>
        <div
          className="scrollable-x-container__arrow scrollable-x-container__arrow--left"
          onClick={nudgeLeft}
          style={{display: displayLeftArrow ? 'flex' : 'none'}}
        ><div className="util-arrow-left" /></div>
        <div
          className="scrollable-x-container__arrow scrollable-x-container__arrow--right"
          onClick={nudgeRight}
          style={{display: displayRightArrow ? 'flex' : 'none'}}
        ><div className="util-arrow-right" /></div>
      </>}
    </div>
  )
}

export default ScrollableXContainer