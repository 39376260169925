import { memo } from 'react'

const FormFieldTextarea = ({ value, onChange, placeholder, isInvalid, validateField, className = '' }) => {
  return (
    <div className={`cart-form-field-wrapper ${className}`}>
      <div className={`cart-form-field-label ${value?.length ? 'cart-form-field-label--active' : ''}`}>
        {placeholder}
      </div>
      <textarea
        value={value ? value : ''}
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) => validateField ? validateField(e.target.value) : true}
        placeholder={placeholder}
        type="text"
        className={`cart-form-field-wrapper-input textarea ${isInvalid === true ? ' field-invalid' : ''}`}
        rows={5}
      />
    </div>
  )
}

export default memo(FormFieldTextarea)