const GiftCertificateBreadcrumbs = ({ step }) => {
  return (
    <div className="gift-certificate-breadcrumbs">
      <div className={`gift-certificate-breadcrumb ${step === 1 ? 'active' : ''}`}>1. Дизайн</div>
      <div className="util-arrow-right" />
      <div className={`gift-certificate-breadcrumb ${step === 2 ? 'active' : ''}`}>2. Номинал</div>
      <div className="util-arrow-right" />
      <div className={`gift-certificate-breadcrumb ${step === 3 ? 'active' : ''}`}>3. Получатель</div>
      <div className="util-arrow-right" />
      <div className={`gift-certificate-breadcrumb ${step === 4 ? 'active' : ''}`}>4. Оплата</div>
    </div>
  )
}

export default GiftCertificateBreadcrumbs