import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useGetUserQuery } from '../../features/backend/backendApiSlice'
import { lastOrderSet } from '../../features/lastOrder/lastOrderSlice'
import useForm from '../../hooks/useForm'
import GiftCertificateBreadcrumbs from './GiftCertificateBreadcrumbs'
import FormFieldPhone from '../../components/Form/FormFieldPhone'
import FormFieldText from '../../components/Form/FormFieldText'
import FormFieldTextarea from '../../components/Form/FormFieldTextarea'
import { PAYMENT_TYPES, paymentTypes, deliveryTypeElectronicProductSlug } from '../../utils/enums'
import { storeOrder } from '../../utils/api'

const Step3 = ({ step, setStep, design, value, product }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [tab, setTab] = useState(1)

  const { data: user, isError, isLoading, isSuccess, refetch, isFetching } = useGetUserQuery()

  const onOrderSuccess = (order) => {
    dispatch(lastOrderSet(order))

    navigate('/thank-you')
  }

  const {
    fields,
    invalidFields,
    validateFieldMemoized,
    setFieldMemoized,
    setFieldMultiple,
    handleSubmit,
    isSubmitting,
    error,
    setError,
  } = useForm({
    phoneCode: "+7",
    phoneNumber: "___ ___ __ __",
  }, {
    phone: (value) => fields?.phoneNumber?.includes("_"),
    firstName: (value) => tab === 1 && value?.length < 1,
    comments: (value) => tab === 1 && value?.length < 1,
    sender: (value) => tab === 1 && value?.length < 1,
  }, async (fields, setError) => {
    const phone = (`${fields.phoneCode}${fields.phoneNumber}`).replaceAll(' ', '')

    let comments = {
      type: 'gift-certificate',
      receiver: tab === 1 ? 'Другу' : 'Себе',
      phone: phone,
      name: fields.firstName,
      comments: fields.comments,
      sender: fields.sender,
      design: design,
      value: value,
    }

    const data = {
      name: fields.firstName,
      phoneCode: fields.phoneCode,
      phoneNumber: fields.phoneNumber,
      comments: JSON.stringify(comments),
      paymentType: paymentTypes.find((elem) => elem.type === PAYMENT_TYPES.CARD).slug,
      deliveryType: deliveryTypeElectronicProductSlug,
      cartItems: [
        { product_id: product.id, variation: product.variations[0], qty: 1, is_on_sale: false, priceOverride: value * 100 },
      ],
      cartTotalSum: value,
      isForAnotherPerson: false,
    }

    console.log(data)

    const order = await storeOrder(data)

    if (!order?.id) {
      // alert('Произошла ошибка при оформлении заказа; пожалуйста, расскажите об этом нам по телефону или по электронной почте.')
      return
    }

    // eslint-disable-next-line
    const widget = new cp.CloudPayments()

    widget.charge(
      {
        publicId: import.meta.env.VITE_CLOUDPAYMENTS_PUBLIC_ID,
        // accountId: `${result.id}_${Date.now()}`,
        invoiceId: order.id,
        description: "Оплата заказа",
        amount: value,
        currency: "RUB",
        // email: fields.email,
        configuration: {
          paymentMethodsOrder: [
            cp.constant.ExternalPaymentMethods.SbpPay,
            cp.constant.ExternalPaymentMethods.MirPay,
            cp.constant.ExternalPaymentMethods.TinkoffPay,
          ]
        },
        data: {
          phone: phone,
          CloudPayments: {
            CustomerReceipt: {
              Items: [
                {
                  "label": product.name,
                  "price": value,
                  "quantity": 1,
                  "amount": value,
                  // "vat": 0,
                  "method": 1,
                  "object": 1,
                }
              ],
              taxationSystem: 2, // система налогообложения; необязательный, если у вас одна система налогообложения
              // email: fields.email, // e-mail покупателя, если нужно отправить письмо с чеком
              phone: phone, // телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
              isBso: false, // чек является бланком строгой отчетности
              amounts: {
                electronic: value, // Сумма оплаты электронными деньгами
                advancePayment: 0.0, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
                credit: 0.0, // Сумма постоплатой(в кредит) (2 знака после запятой)
                provision: 0.0, // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
              },
            },
          },
        },
      },
      function (options) {
        // success

        console.log('success')
        console.log(options)

        onOrderSuccess(order)
      },
      function (reason, options) {
        // fail

        console.log('fail')
        console.log(reason, options)
      },
      function (paymentResult, options) {
        // onComplete
        // Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
        // например вызов вашей аналитики Facebook Pixel
      }
    )
  })

  useEffect(() => {
    if (!isLoading && user?.id && tab === 2) {
      setFieldMultiple({
        phoneCode: user.customer?.phone_code,
        phoneNumber: user.customer?.phone_number,
        // firstName: user.customer?.first_name,
      })
    }

    if (tab === 1) {
      setFieldMultiple({
        phoneCode: "+7",
        phoneNumber: "___ ___ __ __",
        // firstName: '',
      })
    }
  }, [isLoading, user, tab])

  return (
    <>
      <GiftCertificateBreadcrumbs step={step} />
      <div className="heading-h1">Укажите, кому карта</div>
      <div>
        <div className="gift-certificate-receiver-container">
          <div className="gift-certificate-design-selected">
            <div className="gift-certificate-design-selected__picture"><img src={import.meta.env.VITE_BACKEND_URL + design} alt="" /></div>
          </div>
          <div className="gift-certificate-receiver-form-container">
            <div className="gift-certificate-receiver-tabs">
              <div className={`gift-certificate-receiver-tab ${tab === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>Другу</div>
              <div className={`gift-certificate-receiver-tab ${tab === 2 ? 'active' : ''}`} onClick={() => setTab(2)}>Себе</div>
            </div>
            <div className="gift-certificate-receiver-form">
              <form onSubmit={handleSubmit}>
                <div className="form-fields-container-1col">
                  <FormFieldPhone
                    phoneCode={fields?.phoneCode ?? ''}
                    phoneNumber={fields?.phoneNumber ?? ''}
                    isInvalid={invalidFields.phone}
                    setPhoneCode={useCallback((phoneCode, mask) => {
                      setFieldMultiple({
                        phoneCode: phoneCode,
                        phoneNumber: mask,
                      })
                    }, [])}
                    setPhoneNumber={setFieldMemoized('phoneNumber')}
                    validateField={validateFieldMemoized('phone')}
                  />
                  <FormFieldText
                    value={fields.firstName ?? ''}
                    isInvalid={invalidFields.firstName}
                    placeholder={'Имя получателя'}
                    onChange={setFieldMemoized('firstName')}
                    validateField={validateFieldMemoized('firstName')}
                    className={`${tab === 2 ? 'display-none' : ''}`}
                  />
                  <FormFieldTextarea
                    value={fields?.comments ?? ''}
                    isInvalid={invalidFields.comments}
                    placeholder={'Поздравление'}
                    onChange={setFieldMemoized('comments')}
                    validateField={validateFieldMemoized('comments')}
                    className={`${tab === 2 ? 'display-none' : ''}`}
                  />
                  <FormFieldText
                    value={fields.sender ?? ''}
                    isInvalid={invalidFields.sender}
                    placeholder={'От кого'}
                    onChange={setFieldMemoized('sender')}
                    validateField={validateFieldMemoized('sender')}
                    className={`${tab === 2 ? 'display-none' : ''}`}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="gift-certificate-step-controls">
          <button className="button-big-primary-wide muted" onClick={() => setStep(2)}><div className="util-arrow-left" /> Вернуться</button>
          <button className="button-big-primary-wide" onClick={handleSubmit}>{isSubmitting ? 'Оформляем...' : 'Оплатить'}</button>
        </div>
      </div>
    </>
  )
}

export default Step3