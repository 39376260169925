import { useState } from 'react'
import GiftCertificateBreadcrumbs from './GiftCertificateBreadcrumbs'
import GiftCertificateControls from './GiftCertificateControls'
import ScrollableXContainer from '../ScrollableXContainer'

const Step2 = ({ step, setStep, design, valueOptions, value, setValue }) => {
  const [valueDropdownOpen, setValueDropdownOpen] = useState(false)

  const handleSelectValue = (option) => {
    setValue(option)
    setValueDropdownOpen(false)
  }

  return (
    <>
      <GiftCertificateBreadcrumbs step={step} />
      <div className="heading-h1">Выберите номинал</div>
      <div>
        <ScrollableXContainer handle=".gift-certificate-value-options-scrollable" className="gift-certificate-value-options-scrollable hide-xs" displayArrows={true}>
          <div className="gift-certificate-value-options">
            {valueOptions.map((option, index) => (
              <div
                key={index}
                className={`gift-certificate-value-option ${value == option ? 'selected' : ''}`}
                onClick={() => setValue(option)}
              >{option}</div>
            ))}
          </div>
        </ScrollableXContainer>
        <div className="gift-certificate-value-options hide-sm hide-md hide-lg hide-xl hide-xxl">
          <div className={`gift-certificate-value-select ${valueDropdownOpen ? 'active' : ''}`} onClick={() => setValueDropdownOpen(!valueDropdownOpen)}>
            {value}
            <div className="util-arrow-down" />
          </div>
          <div className={`gift-certificate-value-dropdown ${valueDropdownOpen ? 'active' : ''}`}>
            {valueOptions.map((option, index) => (
              <div className="gift-certificate-value-dropdown-option" key={index} onClick={() => handleSelectValue(option)}>{option}</div>
            ))}
          </div>
        </div>
        <div className="gift-certificate-design-selected">
          <div className="gift-certificate-design-selected__picture"><img src={import.meta.env.VITE_BACKEND_URL + design} alt="" /></div>
        </div>
        <GiftCertificateControls step={step} setStep={setStep} />
      </div>
    </>
  )
}

export default Step2