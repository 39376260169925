import { useState } from 'react'
import useTitle from "../../hooks/useTitle"

const UserAgreementView = () => {
  useTitle('Пользовательское соглашение')

  const [activeTab, setActiveTab] = useState(1)

  return (
    <>
      <div className="container">
        <div className="heading-h1">Пользовательское<br />соглашение</div>
        <div className="tabs-container tabs-container-user-agreement">
          <div className="tabs">
            <div className={`tab-link ${activeTab == 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>Основные понятия</div>
            <div className={`tab-link ${activeTab == 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>1. Общие положения</div>
            <div className={`tab-link ${activeTab == 3 ? 'active' : ''}`} onClick={() => setActiveTab(3)}>2. Предмет соглашения</div>
            <div className={`tab-link ${activeTab == 4 ? 'active' : ''}`} onClick={() => setActiveTab(4)}>3. Регистрация на сайте</div>
            <div className={`tab-link ${activeTab == 5 ? 'active' : ''}`} onClick={() => setActiveTab(5)}>4. Товар и порядок совершения покупки</div>
            <div className={`tab-link ${activeTab == 6 ? 'active' : ''}`} onClick={() => setActiveTab(6)}>5. Доставка заказа</div>
            <div className={`tab-link ${activeTab == 7 ? 'active' : ''}`} onClick={() => setActiveTab(7)}>6. Оплата товара</div>
            <div className={`tab-link ${activeTab == 8 ? 'active' : ''}`} onClick={() => setActiveTab(8)}>7. Возврат товара и денежных средств</div>
            <div className={`tab-link ${activeTab == 9 ? 'active' : ''}`} onClick={() => setActiveTab(9)}>8. Ответственность</div>
            <div className={`tab-link ${activeTab == 10 ? 'active' : ''}`} onClick={() => setActiveTab(10)}>9. Конфиденциальность и защита информации</div>
          </div>
          <div className="tabs-content">
            <div className={activeTab != 1 ? 'display-none' : ''}>
              <div className="heading-h2-branded">Основные понятия</div>
              <p>Посетитель Сайта — лицо, посетившее https://rockets.coffee без оформления Заказа.</p>
              <p>Сайт — https://rockets.coffee</p>
              <p>Пользователь — физическое лицо, посетитель Сайта, принимающий условия настоящего Соглашения и желающий разместить Заказы в Интернет-магазине Rockets.coffee.</p>
              <p>Покупатель — Пользователь, разместивший Заказ в Интернет-магазине Rockets.coffee.</p>
              <p>Рокетс — ООО «Рокетс Шоп» (ОГРН 1247700717978, ИНН 9725172444 ОКАТО 45296559000, ОКПО 82132286, место нахождения: 115054, г. Москва, ул. Валовая, д. 35Б).</p>
              <p>Продавец — Компания Rockets, которая реализует собственную продукцию и продукцию сторонних производителей, представленную на сайте https://rockets.coffee.</p>
              <p>Интернет-магазин — Интернет-сайт, принадлежащий Рокетс , расположенный в сети интернет по адресу https://rockets.coffee, где представлены Товары, предлагаемые для приобретения, а также условия оплаты и доставки Товаров Покупателям.</p>
              <p>Товар — кофе, чай, аксессуары и иные товары, представленные к продаже на Сайте.</p>
              <p>Заказ — оформленный запрос Покупателя на приобретение и доставку по указанному Покупателем адресу / посредством самовывоза Товаров, выбранных на Сайте.</p>
            </div>
            <div className={activeTab != 2 ? 'display-none' : ''}>
              <div className="user-agreement-heading">
                <div className="heading-h2-branded user-agreement-heading-number">1.</div>
                <div className="heading-h2-branded">Общие положения</div>
              </div>
              <p>1.1. Продавец осуществляет продажу Товаров через Интернет-магазин по адресу http://www.rockets.coffee.</p>
              <p>1.2. Заказывая Товары через Интернет-магазин, Пользователь соглашается с условиями продажи Товаров, изложенными ниже (далее — Условия продажи товаров). В случае несогласия с настоящим Пользовательским соглашением (далее — Соглашение / Публичная оферта) Пользователь обязан немедленно прекратить использование сервиса и покинуть сайт http://www.rockets.coffee.</p>
              <p>1.3. Настоящие Условия продажи товаров, а также информация о Товаре, представленная на Сайте, являются публичной офертой в соответствии со ст.435 и п.2 ст.437 Гражданского кодекса Российской Федерации.</p>
              <p>1.4. Соглашение может быть изменено Продавцом в одностороннем порядке без уведомления Пользователя/Покупателя. Новая редакция Соглашения вступает в силу по истечении 10 (Десяти) календарных дней с момента ее опубликования на Сайте, если иное не предусмотрено условиями настоящего Соглашения.</p>
              <p>1.5. Публичная оферта признается акцептованной Посетителем Сайта / Покупателем с момента регистрации Посетителя на Сайте, оформления Покупателем Заказа без авторизации на Сайте, а также с момента принятия от Покупателя Заказа по телефону +7 (495) 108-11-44 или +7 (800) 777-04-14. Договор розничной купли-продажи считается заключенным с момента выдачи Продавцом Покупателю кассового или товарного чека либо иного документа, подтверждающего оплату товара. Сообщая Продавцу свой e-mail и номер телефона, Посетитель Сайта/Пользователь/Покупатель дает согласие на использование указанных средств связи. Продавцом, а также третьими лицами, привлекаемыми им для целей выполнения обязательств перед Посетителями Сайта/Пользователями/Покупателями, в целях осуществления рассылок рекламного и информационного характера, содержащих информацию о скидках, предстоящих и действующих акциях и других мероприятиях Продавца, о передаче заказа в доставку, а также иную информацию, непосредственно связанную с выполнением обязательств Покупателем в рамках настоящей Публичной оферты.</p>
              <p>1.6. Осуществляя Заказ, Пользователь/Покупатель соглашается с тем, что Продавец может поручить исполнение Договора третьему лицу, при этом оставаясь ответственным за его исполнение.</p>
              <p>1.7. В рамках Заказа Рокетс предоставляет Пользователю информационное сопровождение заключенного Пользователем с Продавцом Договора.</p>
            </div>
            <div className={activeTab != 3 ? 'display-none' : ''}>
              <div className="user-agreement-heading">
                <div className="heading-h2-branded user-agreement-heading-number">2.</div>
                <div className="heading-h2-branded">Предмет соглашения</div>
              </div>
              <p>2.1. Предметом настоящего Соглашения является предоставление возможности Пользователю приобретать для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности, Товары, представленные в каталоге Интернет-магазина по адресу http://www.rockets.coffee</p>
              <p>2.2. Данное Соглашение распространяется на все виды Товаров и услуг, представленных на Сайте, пока такие предложения с описанием присутствуют в каталоге Интернет-магазина.</p>
            </div>
            <div className={activeTab != 4 ? 'display-none' : ''}>
              <div className="user-agreement-heading">
                <div className="heading-h2-branded user-agreement-heading-number">3.</div>
                <div className="heading-h2-branded">Регистрация на сайте</div>
              </div>
              <p>3.1. Регистрация на Сайте осуществляется с помощью всплывающего окна «Регистрация».</p>
              <p>3.2. Регистрация на Сайте является обязательной для оформления Заказа.</p>
              <p>3.3. Рокетс не несет ответственности за точность и правильность информации, предоставляемой Пользователем при регистрации.</p>
              <p>3.4. Пользователь обязуется не сообщать третьим лицам логин и пароль, указанные Пользователем при регистрации. В случае возникновения у Пользователя подозрений относительно безопасности его логина и пароля или возможности их несанкционированного использования третьими лицами, Пользователь обязуется незамедлительно уведомить об этом Рокетс, направив соответствующее электронное письмо по адресу: info@rockets.coffee.</p>
              <p>3.5. Общение Пользователя/Покупателя с операторами Call-центра / менеджерами и иными представителями Рокетс и должно строиться на принципах общепринятой морали и коммуникационного этикета. Строго запрещено использование нецензурных слов, брани, оскорбительных выражений, а также угроз и шантажа, в независимости от того, в каком виде и кому они были адресованы.</p>
            </div>
            <div className={activeTab != 5 ? 'display-none' : ''}>
              <div className="user-agreement-heading">
                <div className="heading-h2-branded user-agreement-heading-number">4.</div>
                <div className="heading-h2-branded">Товар и порядок совершения покупки</div>
              </div>
              <p>4.1. В случае отсутствия заказанных Покупателем Товаров на складе Продавца, последний вправе исключить указанный Товар из Заказа / отменить Заказ Покупателя, уведомив об этом Покупателя путем направления соответствующего электронного сообщения по адресу, указанному Покупателем при регистрации (либо звонком менеджера Рокетс).</p>
              <p>4.2. В случае отмены предоплаченного Заказа стоимость Товара возвращается Продавцом Покупателю способом, которым Товар был оплачен.</p>
              <p>4.3. Покупатель несет полную ответственность за предоставление неверных сведений, повлекшее за собой невозможность надлежащего исполнения Продавцом своих обязательств перед Покупателем.</p>
              <p>4.4. После оформления Заказа на Сайте Покупателю предоставляется информация о предполагаемой дате доставки путем направления электронного сообщения по адресу, указанному Покупателем при регистрации, или по телефону. Менеджер, обслуживающий данный Заказ, уточняет детали Заказа, согласовывает дату доставки, наличия заказанных Товаров на складе Продавца и времени, необходимого для обработки и доставки Заказа.</p>
              <p>4.5. Ожидаемая дата передачи Заказа в Службу доставки сообщается Покупателю менеджером, обслуживающим Заказ, по электронной почте или при контрольном звонке Покупателю. Дата передачи Товара может быть изменена Продавцом в одностороннем порядке в случае наличия объективных, по мнению Продавца, причин.</p>
            </div>
            <div className={activeTab != 6 ? 'display-none' : ''}>
              <div className="user-agreement-heading">
                <div className="heading-h2-branded user-agreement-heading-number">5.</div>
                <div className="heading-h2-branded">Доставка заказа</div>
              </div>
              <p>5.1. Способы, а также примерные сроки доставки реализуемых Товаров, указаны на Сайте в разделе «Доставка и оплата» по адресу https://rockets.coffee/shipping-and-payment. Конкретные сроки доставки могут быть согласованы Покупателем менеджером при подтверждении заказа.</p>
              <p>5.2. Территория доставки Товаров, представленных на Сайте, ограничена пределами Российской Федерации.</p>
              <p>5.3. Задержки в доставке возможны ввиду непредвиденных обстоятельств, произошедших не по вине Продавца.</p>
              <p>5.4. При доставке Заказ вручается Покупателю либо третьему лицу, указанному в Заказе в качестве получателя (далее Покупатель и третье лицо именуются «Получатель»). При невозможности получения Заказа, оплаченного посредством наличного расчета, указанными выше лицами, Заказ может быть вручен лицу, который может предоставить сведения о Заказе (номер отправления и/или ФИО Получателя), а также оплатить стоимость Заказа в полном объеме лицу, осуществляющему доставку Заказа.</p>
              <p>5.5. Во избежание случаев мошенничества, а также для выполнения взятых на себя обязательств, указанных в пункте 5. настоящего Соглашения, при вручении предоплаченного Заказа лицо, осуществляющее доставку Заказа, вправе затребовать документ, удостоверяющий личность Получателя, а также указать тип и номер предоставленного Получателем документа на квитанции к Заказу. Продавец гарантирует конфиденциальность и защиту персональных данных Получателя (п.9.3.).</p>
              <p>5.6. Обязанность Продавца передать товар Покупателю считается исполненной в момент вручения курьером Товара Получателю или получения Товара Получателем в отделении почтовой связи либо в заранее оговоренном месте выдачи Заказа (в т.ч. в пункте самовывоза).</p>
              <p>5.7. При принятии Заказа от курьера, Получатель обязан осмотреть доставленный Товар и проверить его на соответствие заявленному количеству, ассортименту и комплектности Товара, а также проверить срок службы доставленного Товара и целостность упаковки. В случае отсутствия претензий к доставленному Товару Получатель расписывается в документе, предоставляемом курьером, и оплачивает Заказ (в отсутствие 100%-ной предоплаты). Подпись в доставочных документах свидетельствует о том, что претензий к Товару Получателем не заявлено и Продавец полностью и надлежащим образом выполнил свою обязанность по передаче Товара.</p>
              <p>5.8. Товар, представленный на Сайте, по качеству и упаковке соответствует ГОСТу и ТУ, что подтверждается соответствующими документами (сертификатами и т.д.).</p>
              <p>5.9. Пользователь понимает и соглашается с тем, что: осуществление доставки — отдельная услуга, не являющаяся неотъемлемой частью приобретаемого Покупателем Товара, выполнение которой заканчивается в момент получения Получателем Товара и осуществления платежа за него. Претензии к качеству приобретенного Товара, возникшие после получения и оплаты Товара, рассматриваются в соответствии с Законом РФ «О защите прав потребителей».</p>
            </div>
            <div className={activeTab != 7 ? 'display-none' : ''}>
              <div className="user-agreement-heading">
                <div className="heading-h2-branded user-agreement-heading-number">6.</div>
                <div className="heading-h2-branded">Оплата товара</div>
              </div>
              <p>6.1. Цена товара, реализуемого в Интернет-магазине, указывается в рублях Российской Федерации.</p>
              <p>6.2. Цена Товара на Сайте может быть изменена Продавцом в одностороннем порядке. При этом цена на заказанный Покупателем Товар изменению не подлежит. Цена Товара может дифференцироваться по регионам.</p>
              <p>6.3. Особенности оплаты Товара с помощью банковских карт:</p>
              <p>6.3.1. В соответствии с положением ЦБ РФ «Об эмиссии банковских карт и об операциях, совершаемых с использованием платежных карт» от 24.12.2004 № 266-П операции по банковским картам совершаются держателем карты либо уполномоченным им лицом.</p>
              <p>6.3.2. Авторизация операций по банковским картам осуществляется банком. Если у банка есть основания полагать, что операция носит мошеннический характер, то банк вправе отказать в осуществлении данной операции. Мошеннические операции с банковскими картами попадают под действие статьи 159 УК РФ.</p>
              <p>6.3.3. Во избежание случаев различного рода неправомерного использования банковских карт при оплате все Заказы, оформленные на Сайте и предоплаченные банковской картой, проверяются Продавцом. В целях проверки личности владельца и его правомочности на использование карты Продавец вправе потребовать от Покупателя, оформившего такой заказ, предъявления документа, удостоверяющего личность.</p>
            </div>
            <div className={activeTab != 8 ? 'display-none' : ''}>
              <div className="user-agreement-heading">
                <div className="heading-h2-branded user-agreement-heading-number">7.</div>
                <div className="heading-h2-branded">Возврат товара и денежных средств</div>
              </div>
              <p>7.1. Возврат Товара, реализуемого Рокетс, осуществляется в соответствии с «Условиями возврата», указанными на Сайте по адресу https://rockets.coffee/dostavka-i-oplata.</p>
              <p>7.2. Возврат Товара надлежащего качества.</p>
              <p>7.2.1. При отказе Покупателя от Товара Продавец возвращает ему стоимость возвращенного Товара, за исключением расходов Продавца, связанных с доставкой возвращенного Покупателем Товара, в течение 10 дней с даты поступления возвращенного Товара на склад Продавца вместе с заполненным Покупателем заявлением на возврат.</p>
              <p>7.2.2. Если на момент обращения Покупателя аналогичный товар отсутствует в продаже у Продавца, Покупатель вправе отказаться от исполнения настоящего Соглашения и потребовать возврата уплаченной за указанный Товар денежной суммы. Продавец обязан вернуть уплаченную за возвращенный товар денежную сумму в течение 10 дней со дня возврата Товара.</p>
              <p>7.3. Возврат Товара ненадлежащего качества:</p>
              <p>7.3.1. Под товаром ненадлежащего качества подразумевается товар, который неисправен и не может обеспечить исполнение своих функциональных качеств. Полученный Товар должен соответствовать описанию на Сайте.</p>
              <p>7.3.2. Внешний вид и комплектность Товара, а также комплектность всего Заказа должны быть проверены Получателем в момент доставки Товара.</p>
              <p>7.3.3. При доставке Товара Покупатель ставит свою подпись в квитанции о доставке в графе: «Заказ принял, претензий к количеству и качеству товара не имею».</p>
              <p>7.3.4. Если Покупателю был передан Товар ненадлежащего качества и оное не было заранее оговорено Продавцом, Покупатель вправе воспользоваться положениями ст.18 «Права потребителя при обнаружении в товаре недостатков» Закона о защите прав потребителей.</p>
              <p>7.3.5. Требования о возврате уплаченной за товар денежной суммы подлежат удовлетворению в течение 10 дней со дня предъявления соответствующего требования (ст.22 Закона РФ «О защите прав потребителей»).</p>
              <p>7.4. Возврат денежных средств осуществляется посредством возврата стоимости оплаченного Товара на банковскую карту или почтовым переводом. Способ должен быть указан в соответствующем поле заявления на возврат Товара.</p>
            </div>
            <div className={activeTab != 9 ? 'display-none' : ''}>
              <div className="user-agreement-heading">
                <div className="heading-h2-branded user-agreement-heading-number">8.</div>
                <div className="heading-h2-branded">Ответственность</div>
              </div>
              <p>8.1. Продавец не несет ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего использования Товаров, приобретенных в Интернет-магазине.</p>
              <p>8.2. Продавец не несет ответственности за содержание и функционирование внешних сайтов.</p>
            </div>
            <div className={activeTab != 10 ? 'display-none' : ''}>
              <div className="user-agreement-heading">
                <div className="heading-h2-branded user-agreement-heading-number">9.</div>
                <div className="heading-h2-branded">Конфиденциальность и защита информации</div>
              </div>
              <p>9.1. Персональные данные Пользователя/Покупателя обрабатывается в соответствии с ФЗ «О персональных данных» № 152-ФЗ.</p>
              <p>9.2. При регистрации на Сайте Пользователь предоставляет следующую информацию: Фамилия, Имя, Отчество, контактный номер телефона, адрес электронной почты, дату рождения, адрес доставки товара.</p>
              <p>9.3. Предоставляя свои персональные данные Продавцу, Посетитель Сайта/Пользователь/Покупатель соглашается на их обработку Продавцом, в том числе в целях выполнения Продавцом обязательств перед Посетителем Сайта/Пользователем/Покупателем в рамках настоящей Публичной оферты. Также, персональные данные могут быть использованы Продавцом для продвижения товаров и услуг, проведения электронных и sms опросов, контроля результатов маркетинговых акций, клиентской поддержки, организации доставки товара Покупателям, проведение розыгрышей призов среди Посетителей Сайта/Пользователей/ Покупателей, контроля удовлетворенности Посетителя Сайта/Пользователя/Покупателя, качества услуг, оказываемых Продавцом.</p>
              <p>9.4. Под обработкой персональных данных понимается любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение) извлечение, использование, обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
              <p>9.5. Продавец имеет право отправлять информационные, в том числе рекламные сообщения, на электронную почту и мобильный телефон Пользователя/Покупателя с его согласия, выраженного посредством совершения им действий, однозначно идентифицирующих этого абонента и позволяющих достоверно установить его волеизъявление на получение сообщения. Пользователь/Покупатель вправе отказаться от получения рекламной и другой информации без объяснения причин отказа путем информирования Рокетс о своем отказе по телефону +7 (495) 108-11- 44 либо посредством направления соответствующего заявления на электронный адрес Продавца: info@rockets.coffee. Сервисные сообщения, информирующие Пользователя/Покупателя о заказе и этапах его обработки, отправляются автоматически и не могут быть отклонены Пользователем/Покупателем.</p>
              <p>9.6. Продавец вправе использовать технологию «cookies». «Cookies» не содержат конфиденциальную информацию. Посетитель / Пользователь / Покупатель настоящим дает согласие на сбор, анализ и использование cookies, в том числе третьими лицами для целей формирования статистики и оптимизации рекламных сообщений.</p>
              <p>9.7. Продавец получает информацию об ip-адресе посетителя Сайта www.rockets.coffee. Данная информация не используется для установления личности посетителя.</p>
              <p>9.8. Продавец вправе осуществлять записи телефонных разговоров с Пользователем/Покупателем. При этом Продавец обязуется: предотвращать попытки несанкционированного доступа к информации, полученной в ходе телефонных переговоров, и/или передачу ее третьим лицам, не имеющим непосредственного отношения к исполнению Заказов, в соответствии с п. 4 ст. 16 Федерального закона «Об информации, информационных технологиях и о защите информации».</p>
              <p>10. Срок действия Публичной оферты</p>
              <p>10.1. Настоящая Публичная оферта вступает в силу с момента ее акцепта Посетителем Сайта/Покупателем, и действует до момента отзыва акцепта Публичной оферты.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserAgreementView