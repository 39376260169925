import { useLocation } from 'react-router-dom'
import CatalogTabLink from "./CatalogTabLink"
import CatalogSorter from "./CatalogSorter"

const CatalogTabNav = () => {
  const { pathname } = useLocation()

  const urlParams = new URLSearchParams(window.location.search)
  const q = urlParams.get('q')
  let query = q ? `?q=${encodeURIComponent(q)}` : ''

  return (
    <div className="catalog-tab-nav-wrapper">
      <div className="catalog-tab-nav-container">
        <div className="catalog-tab-nav-content">
          {/* <CatalogTabLink pathname={pathname} path="catalog/chernaya-pyatnica" label={<img src="/images/black-friday.svg" alt="" />} className="catalog-tab-nav-link--image" /> */}
          <CatalogTabLink pathname={pathname} path="catalog/weeks-choice" label="Выбор недели" />
          <CatalogTabLink pathname={pathname} path="catalog/coffee" label="Кофе в зёрнах" />
          <CatalogTabLink pathname={pathname} path={`catalog/coffee-drips`} label="Кофе в дрип-пакетах" />
          <CatalogTabLink pathname={pathname} path="catalog/coffee-capsules" label="Кофе в капсулах" />
          <CatalogTabLink pathname={pathname} path="catalog/tea" label={<img src="/images/logo-keen-tiger.svg" alt="" />} className="catalog-tab-nav-link--image" />
          <CatalogTabLink pathname={pathname} path="catalog/matcha" label="Матча" />
          <CatalogTabLink pathname={pathname} path="catalog/notcoffee" label="Не кофе" />
          <CatalogTabLink pathname={pathname} path="catalog/accessories" label="Аксессуары" />
          {/* <CatalogTabLink pathname={pathname} path="catalog/black-friday" label="Black Friday" /> */}
          {/* <CatalogTabLink pathname={pathname} path="catalog/merch" label="Мерч" /> */}
        </div>
      </div>
      <CatalogSorter isRight={true} className="hide-xs hide-sm hide-md" />
    </div>
  )
}

export default CatalogTabNav