import ScrollableXContainer from '../ScrollableXContainer'
import GiftCertificateBreadcrumbs from './GiftCertificateBreadcrumbs'
import GiftCertificateControls from './GiftCertificateControls'

const Step1 = ({ step, setStep, designOptions, colorOptions, design, setDesign, color, setColor }) => {
  return (
    <>
      <GiftCertificateBreadcrumbs step={step} />
      <div className="heading-h1">Выберите дизайн</div>
      <div>
        <ScrollableXContainer handle=".gift-certificate-design-options-scrollable" className="gift-certificate-design-options-scrollable" displayArrows={true}>
          <div className="gift-certificate-design-options">
            {designOptions.map((option, index) => (
              <div
                key={index}
                className={`gift-certificate-design-option ${design == option ? 'selected' : ''}`}
                onClick={() => setDesign(option)}
              ><img src={import.meta.env.VITE_BACKEND_URL + option} alt="" /></div>
            ))}
          </div>
        </ScrollableXContainer>
        <div className="gift-certificate-design-selected">
          <div className="gift-certificate-design-selected__picture"><img src={import.meta.env.VITE_BACKEND_URL + design} alt="" /></div>
          {/* <ScrollableXContainer handle=".gift-certificate-color-options-scrollable" className="gift-certificate-color-options-scrollable hide-xs" displayArrows={false}>
            <div className="gift-certificate-color-options">
              {colorOptions.map((option, index) => (
                <div
                  key={index}
                  className={`gift-certificate-color-option ${color == option ? 'selected' : ''}`}
                  style={{backgroundColor: option}}
                  onClick={() => setColor(option)}
                />
              ))}
            </div>
          </ScrollableXContainer> */}
        </div>
        {/* <ScrollableXContainer handle=".gift-certificate-color-options-scrollable--mobile" className="gift-certificate-color-options-scrollable--mobile hide-sm hide-md hide-lg hide-xl hide-xxl" displayArrows={false}>
          <div className="gift-certificate-color-options">
            {colorOptions.map((option, index) => (
              <div
                key={index}
                className={`gift-certificate-color-option ${color == option ? 'selected' : ''}`}
                style={{backgroundColor: option}}
                onClick={() => setColor(option)}
              />
            ))}
          </div>
        </ScrollableXContainer> */}
        <GiftCertificateControls step={step} setStep={setStep} />
      </div>
    </>
  )
}

export default Step1