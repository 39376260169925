import useTitle from '../hooks/useTitle'
import { useParams } from 'react-router-dom'
import { useGetCertificatePurchasedQuery } from '../features/backend/backendApiSlice'
import GiftCertificateDisplay from '../components/GiftCertificate/GiftCertificateDisplay'
import NotFound from '../components/NotFound'

const GiftCertificateOneView = () => {
  useTitle('Подарочный сертификат')

  const { code } = useParams()

  console.log(code)

  const { data, isError, isLoading, isSuccess } = useGetCertificatePurchasedQuery({ code })

  if (isSuccess && data.error) {
    return <NotFound />
  }

  console.log(data)

  return (
    <div className="container">
      {isLoading && <div className="loading-rocket"><img src="/images/loading-rocket.svg" alt="" /></div>}
      {isSuccess && !data.error && <GiftCertificateDisplay data={data} />}
    </div>
  )
}

export default GiftCertificateOneView