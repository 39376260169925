import { Link } from "react-router-dom"

const Step0 = ({ step, setStep }) => {
  return (
    <>
      <div className="heading-h1">Выберите сертификат</div>
      <div className="gift-certificate-type-container">
        <div className="gift-certificate-type-wrapper" onClick={() => setStep(1)}>
          <div className="gift-certificate-type">
            <div className="gift-certificate-type__heading">Электронный</div>
            <div className="gift-certificate-type__picture"><img src="/images/certificate-electronic.new.png" alt="" /></div>
            <button className="button-big-primary-wide hide-xs hide-sm hide-md">Выбрать</button>
          </div>
          <button className="button-big-primary-wide hide-lg hide-xl hide-xxl">Выбрать</button>
        </div>
        <Link to="/catalog/search?q=подарочный%20сертификат" className="gift-certificate-type-wrapper">
          <div className="gift-certificate-type">
            <div className="gift-certificate-type__heading">Пластиковый</div>
            <div className="gift-certificate-type__picture"><img src="/images/certificate-plastic.png" alt="" /></div>
          </div>
          <button className="button-big-primary-wide hide-lg hide-xl hide-xxl">Выбрать</button>
        </Link>
      </div>
    </>
  )
}

export default Step0