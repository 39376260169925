import { Link } from 'react-router-dom'

const GiftCertificateDisplay = ({ data }) => {
  return (
    <>
      <div className="heading-h1">Ваш сертификат</div>
      <div>
        <div className="gift-certificate-display-container">
          <div className="gift-certificate-design-selected">
            <div className="gift-certificate-design-selected__picture"><img src={import.meta.env.VITE_BACKEND_URL + data.notes.design} alt="" /></div>
          </div>
          <div className="gift-certificate-display-details">
            <div className="gift-certificate-display-details__heading">Информация</div>
            <div className="gift-certificate-display-details__code">Номер сертификата: <span>{data.notes.code}</span></div>
            <div className="gift-certificate-display-details__2col">
              <div className="gift-certificate-display-details__block">
                <div className="gift-certificate-display-details__label">Имя получателя</div>
                <div className="gift-certificate-display-details__value">{data.notes.name}</div>
              </div>
              <div className="gift-certificate-display-details__block">
                <div className="gift-certificate-display-details__label">Телефон</div>
                <div className="gift-certificate-display-details__value">{data.notes.phone}</div>
              </div>
            </div>
            {data.notes.comments && <div className="gift-certificate-display-details__block">
              <div className="gift-certificate-display-details__label">Поздравление</div>
              <div className="gift-certificate-display-details__value">{data.notes.comments}</div>
            </div>}
            {data.notes.sender && <div className="gift-certificate-display-details__block">
              <div className="gift-certificate-display-details__label">От кого</div>
              <div className="gift-certificate-display-details__value">{data.notes.sender}</div>
            </div>}
            {/* <div className="gift-certificate-display-details__note">Эта же информация была отправлена на указанный номер телефона</div> */}
          </div>
        </div>
        <div className="gift-certificate-step-controls">
          <Link to="/" className="button-big-primary-wide muted"><div className="util-arrow-left" /> На главную</Link>
          <Link to="/gift-certificate" className="button-big-primary-wide">Оформить новый сертификат</Link>
        </div>
      </div>
    </>
  )
}

export default GiftCertificateDisplay